$space:20px;
$morespace:60px;
body{
    width:100%;
    margin:auto 0;
}
h3{
    color: #333;
    font-weight: 300;
}
.form-control{
    background:#fff;
    height:30px;
    border-radius: 5px;
    box-shadow: none;
    border:1px #ccc solid;
    display:block;
    width:90%;
    margin:$space 5%;
    padding:5px
}
.box{
    display:block;
    border-radius: 5px;
    background:#fff;
    padding:20px;
    width:70%;
}
.col1{
    display:flex;
    flex-direction: column;
    width:8%;
    margin: 5px 1%;
    flex-wrap: wrap;
    
}
.col2{
    display:flex;
    flex-direction: column;
    width:18%;
    margin: 5px 1%;
    flex-wrap: wrap;
}
.col3{
    display:flex;
    flex-direction: column;
    width:28%;
    margin: 5px 1%;
    flex-wrap: wrap;
}
.col4{
    display:flex;
    flex-direction: column;
    width:38%;
    margin: 5px 1%;
    flex-wrap: wrap;
}
.col5{
    display:flex;
    flex-direction: column;
    width:48%;
    margin: 5px 1%;
    flex-wrap: wrap;
}
.col6{
    display:flex;
    flex-direction: column;
    width:58%;
    margin: 5px 1%;
    flex-wrap: wrap;
}
.col7{
    display:flex;
    flex-direction: column;
    width:68%;
    margin: 5px 1%;
    flex-wrap: wrap;
}
.col8{
    display:flex;
    flex-direction: column;
    width:78%;
    margin: 5px 1%;
    flex-wrap: wrap;
}
.col9{
    display:flex;
    flex-direction: column;
    width:88%;
    margin: 5px 1%;
    flex-wrap: wrap;
}
.col10{
    display:flex;
    flex-direction: column;
    width:98%;
    margin: 5px 1%;
    flex-wrap: wrap;
}
@media screen and (max-width:800px) {
    .col1{
        width:18%;
        margin:1%;
    }
    .col2{
        width:38%;
        margin:1%;
    }
    .col3{
        width:38%;
        margin:1%;
    }
    .col4{
        width:48%;
        margin:1%;
    }
    .col5, .col6, .col7, .col8, .col9, .col10{
        width:98%;
        margin:1%;
    }
}
.row{
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    &:first-child{
        margin-left: 0;
    }
    &:last-child{
        margin-right: 0;
    }
}
.center{
    width:90%;
    margin:0px 5%;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.left{
    display:flex;
    width:90%;
    margin:auto 5%;
    justify-content: flex-start;
    align-items: left;
    flex-wrap: wrap;
}
.right{
    display:flex;
    width:90%;
    margin:auto 5%;
    justify-content:flex-end;
    align-items: cenrightter;
    flex-wrap: wrap;
}
.space{
    margin:$space;
}
.morespace{
    margin:$morespace;
}

.btn{
    border:none;
    border-radius: 5px;
    padding:15px 30px;
    width:90%;
    margin:auto 5% 10px;
}
table{
    width:100%;
    thead{
        background: #333;
        color: #fff;
    }
    tr:nth-child(even) {background: #CCC}
    td{
        text-align: center;
    }
}
.success{
    color:#fff;
    background:rgb(30, 165, 82);
}
.topbar{
    padding:$space;
    border-bottom: 1px #ccc solid;
    background:#fff;
    .logo{
        width:100%;
    }
    .nav{
        text-align: right;
        a{
            color:#333;
            margin:auto 5px;
            text-decoration: none;
        }
    }
}
.dashboard{
    background:rgb(216, 216, 216);
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
   // height: 100vh;
    overflow: hidden;
    
}
.statbox{
    .title{
        display: block;
        font-size: 12px;
        min-height: 25px;
    }
    .score{
        display: block;
        font-size: 50px;
        color:rgb(26, 177, 84)
    }
    height:110px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.emailbutton{
    button{
        padding:10px 40px;
        margin:0;
        border-radius: 5px;
        background:rgb(26, 177, 84) ;
        border:none;
        color:#fff;
    }
}